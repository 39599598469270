export const useNotification = () => {
    const $q = useQuasar()

    function notify(type: "positive" | "warning" | "negative", msg: string) {
        $q.notify({
            html: true,
            type: type,
            message:
                type === "positive"
                    ? `
            <div style="display: flex; align-items: center;">
                  <div class="tw-bg-white tw-rounded-full tw-p-1 tw-mr-2 tw-w-6 tw-h-6 tw-m-auto tw-flex tw-justify-center tw-items-center"><svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_2943_5877)"><path d="M10.7062 2.70615C11.0968 2.31553 11.0968 1.68115 10.7062 1.29053C10.3155 0.899902 9.68115 0.899902 9.29053 1.29053L4.9999 5.58428L3.20615 3.79053C2.81553 3.3999 2.18115 3.3999 1.79053 3.79053C1.3999 4.18115 1.3999 4.81553 1.79053 5.20615L4.29053 7.70615C4.68115 8.09678 5.31553 8.09678 5.70615 7.70615L10.7062 2.70615ZM13.7062 6.70615C14.0968 6.31553 14.0968 5.68115 13.7062 5.29053C13.3155 4.8999 12.6812 4.8999 12.2905 5.29053L4.9999 12.5843L1.70615 9.29365C1.31553 8.90303 0.681152 8.90303 0.290527 9.29365C-0.100098 9.68428 -0.100098 10.3187 0.290527 10.7093L4.29053 14.7093C4.68115 15.0999 5.31553 15.0999 5.70615 14.7093L13.7062 6.70928V6.70615Z" fill="#579B00"/></g><defs><clipPath id="clip0_2943_5877"><rect width="14" height="16" fill="white"/></clipPath></defs></svg></div>
              ${msg}
            </div>
          `
                    : type === "warning"
                      ? `${msg}`
                      : `<div style="display: flex; align-items: center;">
                <div class="tw-bg-white tw-rounded-full tw-p-1 tw-mr-2 tw-w-8 tw-h-8 tw-m-auto tw-flex tw-justify-center tw-items-center">
                    <svg width="16" height="16" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.19389 0H3.80611L0 3.80611V9.19389L3.80611 13H9.19389L13 9.19389V3.80611L9.19389 0ZM11.5556 8.59444L8.59444 11.5556H4.40556L1.44444 8.59444V4.40556L4.40556 1.44444H8.59444L11.5556 4.40556V8.59444Z" fill="#FF5B00"/>
                        <path d="M6.50005 10.1114C6.89893 10.1114 7.22228 9.78809 7.22228 9.38921C7.22228 8.99034 6.89893 8.66699 6.50005 8.66699C6.10118 8.66699 5.77783 8.99034 5.77783 9.38921C5.77783 9.78809 6.10118 10.1114 6.50005 10.1114Z" fill="#FF5B00"/>
                        <path d="M5.77783 2.88867H7.22228V7.94423H5.77783V2.88867Z" fill="#FF5B00"/>
                    </svg>
                </div>
            ${msg}
            </div>`,
            position: "top-right",
            classes:
                type === "positive"
                    ? "notify-positive"
                    : type === "warning"
                      ? "notify-warning"
                      : "notify-negative",
        })
    }

    return notify
}
